
















import { Component, Prop, Vue } from 'vue-property-decorator';
import AmountsFormattedInterface from '@/shared/lib/interfaces/AmountsFormattedInterface';

@Component
export default class CartSummaryTotals extends Vue {
  /**
   * Props
   */
  @Prop() private totals!: AmountsFormattedInterface;
}
